.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #f5f5f5;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  
  .navbar__logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar__menu {
    margin-right: 20px;
  }
  