.borda-piscante {
    animation: piscar 1.5s ease-in-out 3;
    border: 1px solid lime;
  }
  
  @keyframes piscar {
    0% {
      opacity: 1;
      border: 1px solid lime;
    }
    50% {
      opacity: 0;
      border: 1px solid lime;
    }
    100% {
      opacity: 1;
      border: 1px solid lime;
    }
  }
  

  .borda-piscante-polo {
    animation: piscar 2.0s ease-in-out 3;
    border: 1px solid yellow;
  }
  
  @keyframes piscar {
    0% {
      opacity: 1;
      border: 1px solid yellow;
    }
    50% {
      opacity: 0;
      border: 1px solid yellow;
    }
    100% {
      opacity: 1;
      border: 1px solid yellow;
    }
  }